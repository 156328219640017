<template>
  <div class="page">
    <div class="page__left">
      <router-link to="/app" class="model" tag="div">
        <img
          src="~@/assets/img/menu/1_icn_sneaker_selezionata.png"
          class="model__image"
        />
      </router-link>
    </div>
    <div class="page__right">
      <vertical-swiper></vertical-swiper>
    </div>
  </div>
</template>

<script>
import VerticalSwiper from '@/components/VerticalSwiper';

export default {
  name: "Sneakers",
  components: {
    VerticalSwiper
  },
};
</script>

<style lang="scss">
.page {
  height: 100%;
  width: 100%;
  display: flex;
  &__left {
    flex: 1 1 50%;
    height: 100%;
    display: flex;
  }
  &__right {
    flex: 1 1 50%;
    background-color: rgba(255, 255, 255, 0.4);
    height: 100%;
    display: flex;
  }
}
.model {
  $classModel: &;
  width: 39.58vw;
  margin: auto 2vw auto auto;
  &__image {
    width: 100%;
    height: auto;
    margin: auto;
  }
  &--carousel {
    height: 14.81vh;
    width: 39.58vw;
    margin: auto auto auto 2vw;
    #{$classModel}__image,
    #{$classModel}__title {
      display: block;
      height: 100%;
      width: auto;
      margin: auto;
    }
    #{$classModel}__title {
      height: 2.59vh;
    }
  }
}
</style>