<template>
    <div class="swiper-container" :class="{'swiper-container--fixed' : type === 'fixed'}">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
            <div class="model--carousel">
                <img
                class="model__image"
                src="~@/assets/img/menu/icn_scarpa_edit_1.png"
                />
                <img
                class="model__title"
                src="~@/assets/img/menu/nome_scarpa_1.svg"
                />
            </div>
        </div>
        <div class="swiper-slide">
            <div class="model--carousel">
                <img
                class="model__image"
                src="~@/assets/img/menu/icn_scarpa_edit_2.png"
                />
                <img
                class="model__title"
                src="~@/assets/img/menu/nome_scarpa_2.svg"
                />
            </div>
        </div>
        <div class="swiper-slide">
            <div class="model--carousel">
                <img
                class="model__image"
                src="~@/assets/img/menu/icn_scarpa_edit_3.png"
                />
                <img
                class="model__title"
                src="~@/assets/img/menu/nome_scarpa_3.svg"
                />
            </div>
        </div>
        <div class="swiper-slide">
            <div class="model--carousel">
                <img
                class="model__image"
                src="~@/assets/img/menu/icn_scarpa_edit_2.png"
                />
                <img
                class="model__title"
                src="~@/assets/img/menu/nome_scarpa_2.svg"
                />
            </div>
        </div>
        <div class="swiper-slide">
            <div class="model--carousel">
                <img
                class="model__image"
                src="~@/assets/img/menu/icn_scarpa_edit_2.png"
                />
                <img
                class="model__title"
                src="~@/assets/img/menu/nome_scarpa_2.svg"
                />
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import Swiper from 'tiny-swiper';

export default {
    name: 'Vertical-Swiper',
    props: {
        type: {
            default: 'smooth',
            type: String
        }
    },
    data() {
        return {
            swiper: null,
            swiperOptions: {
                direction: "vertical",
                slidesPerView: 3,
                centeredSlides: true,
                loop: true,
                resistance: false,
                longSwipesRatio: 0.3,
                longSwipesMs: 100
            }
        }
    },
    methods: {
        createSwiper() {
            this.swiper = new Swiper(".swiper-container", this.swiperOptions);
        },
    },
    mounted() {
        if(this.type === "fixed") {
            this.swiperOptions.slidesPerView = 5;
        }
        this.createSwiper();
    },
}
</script>

<style lang="scss" scoped>
    .swiper-container {
        position: relative;
        overflow: hidden;

        &--fixed {
            .model__title {
                display: none;
            }
            .model--carousel {
                height: 100%;
                width: 100%;
                border-top: 0.052vw solid #d6c496;
            }
            .model__image {
                height: 100%;
                margin: auto;
                display: block;
            }
            .swiper-slide-active {
                .model--carousel {
                    height: 100%;
                    transition: none;
                }
            }
        }
    }
    .swiper-container,
    .swiper-wrapper {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        position: relative;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 18px;
        align-items: center;
        cursor: grab;
    }

    .swiper-slide-active {
        .model--carousel {
            transition: 0.25s height ease-in-out;
            height: 100%;
        }
        .model__title {
            margin-top: -4vh;
        }
    }
</style>